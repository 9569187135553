import { Box, Typography } from '@mui/material';
import {
    getTermFromCode,
    shouldShowUOM,
} from 'shared/lib/unitOfMeasureUtility/unitOfMeasureUtility';

import { CatalogItemRaw } from './CatalogItemRaw';
import { CategoryLabel } from 'entities/Category';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

export interface CatalogItemProps {
    sku: string;
    uom: string;
    qtyPerUom: number;
    minAllowedInStock: number;
    title: string;
    categoryId: string;
    label?: string;
}

export const CatalogItem = memo(
    ({
        sku,
        uom,
        qtyPerUom,
        minAllowedInStock,
        title,
        categoryId,
    }: CatalogItemProps) => {
        const { t } = useTranslation();
        const minAllowedInStockValue = minAllowedInStock || 0;

        return (
            <Box display="flex">
                <Box flex="1">
                    <Typography
                        data-testid="Item-title"
                        typography={'openSans.subtitle1Medium'}
                        mb="12px"
                    >
                        {title}
                    </Typography>

                    {categoryId && (
                        <Box data-testid="Item-category-label" mb="10px">
                            <CategoryLabel categoryId={categoryId} />
                        </Box>
                    )}
                    <Box
                        width="250px"
                        display="flex"
                        flexDirection="column"
                        gap="4px"
                    >
                        <CatalogItemRaw label="SKU" value={sku} />
                        {shouldShowUOM(uom) && (
                            <>
                                <CatalogItemRaw
                                    label={t('Qty per UOM')}
                                    value={qtyPerUom}
                                />
                                <CatalogItemRaw
                                    label="UOM"
                                    value={getTermFromCode(uom)}
                                />
                            </>
                        )}
                        <CatalogItemRaw
                            label="Min On Hand"
                            value={minAllowedInStockValue}
                        />
                    </Box>
                </Box>
            </Box>
        );
    },
);
