import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    Accordion,
    AccordionDetails,
    Box,
    Skeleton,
    Typography,
    alpha,
} from '@mui/material';
import { getSelectedCatalogItemsTotal } from 'entities/CatalogItem/model/selectors/catalogItemSelectors';
import { getLocalCategoriesList } from 'entities/Category';
import { getUserCanSeePrices } from 'entities/User/model/selectors/userPermissionsSeletors';
import { getCurrentOrderSummary } from 'pages/OrderViewPage/model/selectors/orderViewPageSelectors';
import { OrderProposalFilter } from 'features/OrderProposalFilter';
import { UserRole, getUserRole } from 'entities/User';
import { AccordionHeader } from 'shared/ui/AccordionHeader/AccordionHeader';
import { getPrice } from 'shared/lib/getPrice/getPrice';

interface OrderViewSummaryDetailsProps {
    summaryByCategories: Record<
        string,
        {
            count: number;
            cost: number;
        }
    >;
    isLoadingSummaryDetails: boolean;
    isInteractionStatus: boolean;
}

export const OrderViewSummaryDetails = ({
    summaryByCategories,
    isLoadingSummaryDetails,
    isInteractionStatus,
}: OrderViewSummaryDetailsProps) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        setExpanded(!isInteractionStatus);
    }, [isInteractionStatus, setExpanded]);

    const currentUserRole = useSelector(getUserRole);
    const isTechnicianUser =
        currentUserRole === UserRole.MAINTENANCE_TECHNICIAN;

    const totalCategories = Object.values(summaryByCategories).length;

    const categories = useSelector(getLocalCategoriesList);
    const orderSummary = useSelector(getCurrentOrderSummary);
    const canSeePrices = useSelector(getUserCanSeePrices);
    const totalItemsCount = useSelector(getSelectedCatalogItemsTotal);

    const handleChangeExpand = () => {
        setExpanded((prev) => !prev);
    };

    return (
        <Box sx={{ my: '16px' }}>
            <Accordion
                sx={(theme) => ({
                    bgcolor: alpha(theme.palette.grey[500], 0.5),
                    borderRadius: '12px',
                })}
                defaultExpanded={false}
                expanded={expanded}
                onChange={handleChangeExpand}
            >
                <AccordionHeader title={t('Order summary')} />
                <AccordionDetails sx={{ p: '12px' }}>
                    {!isLoadingSummaryDetails ? (
                        <Box>
                            <Box
                                mb="8px"
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Box display="inline-flex" gap="4px">
                                    <Typography
                                        typography={'openSans.subtitle1Medium'}
                                    >
                                        {t('Total Items')} ({totalItemsCount})
                                    </Typography>
                                    <Typography
                                        typography={'openSans.subtitle1Medium'}
                                    >
                                        {t('in Categories')} ({totalCategories})
                                    </Typography>
                                </Box>
                                <Box>
                                    {canSeePrices && (
                                        <Typography typography="openSans.subtitle1Medium">
                                            {getPrice(orderSummary)}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                gap="8px"
                            >
                                {Object.entries(summaryByCategories).map(
                                    ([id, info]) => (
                                        <Box
                                            key={id}
                                            display="flex"
                                            justifyContent="space-between"
                                        >
                                            <Box display="flex" gap="4px">
                                                <Typography>
                                                    {categories?.[id]?.title}
                                                </Typography>
                                                <Typography
                                                    color={(theme) =>
                                                        alpha(
                                                            theme.palette.common
                                                                .black,
                                                            0.5,
                                                        )
                                                    }
                                                >
                                                    {categories?.[id]?.glCode
                                                        ? ` | ${categories?.[id]?.glCode}`
                                                        : null}
                                                </Typography>
                                            </Box>
                                            {canSeePrices && (
                                                <Typography>
                                                    {getPrice(info.cost)}
                                                </Typography>
                                            )}
                                        </Box>
                                    ),
                                )}
                            </Box>
                            {!isTechnicianUser && <OrderProposalFilter />}
                        </Box>
                    ) : (
                        <>
                            <Skeleton width="240px" height="32px" />
                            <Skeleton width="80px" height="32px" />
                            <Skeleton width="180px" height="20px" />
                        </>
                    )}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};
