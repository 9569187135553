import { memo, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';
import { getRouteNotFound, getRouteOrdersView } from 'shared/const/router';
import { getSelectedOrderId } from 'entities/Order/model/selectors/ordersSelectors';
import { catalogItemActions } from 'entities/CatalogItem/model/slices/CatalogItemSlice';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { deleteOrderItem } from 'features/removeItemsInOrder/model/services/deleteOrderItem/deleteOrderItem';
import RemoveOrderModal from 'shared/ui/RemoveOrderModal/RemoveOrderModal';
import { fetchOrder } from 'entities/Order';

interface RemoveItemFromOrderProps {
    itemId: string;
}

export const RemoveItemFromOrder = memo(
    ({ itemId }: RemoveItemFromOrderProps) => {
        const { t } = useTranslation();
        const dispatch = useAppDispatch();
        const navigate = useNavigate();
        const selectedOrderId = useSelector(getSelectedOrderId);

        const [showDialog, setShowDialog] = useState(false);
        const [loading, setLoading] = useState(false);

        const closeDialog = useCallback(() => {
            setShowDialog(false);
        }, []);

        const openDialog = useCallback(() => {
            setShowDialog(true);
        }, []);

        const redirectOnNotFound = useCallback(
            () => navigate(getRouteNotFound()),
            [navigate],
        );

        const submitDialog = useCallback(async () => {
            setLoading(true);
            const params = {
                orderId: selectedOrderId,
                itemId: itemId,
                qty: 0,
            };
            await dispatch(deleteOrderItem(params));
            await dispatch(
                fetchOrder({
                    id: selectedOrderId,
                    onNotFound: redirectOnNotFound,
                }),
            );

            navigate(getRouteOrdersView(selectedOrderId));
            dispatch(catalogItemActions.removeItem(itemId));
            setShowDialog(false);
            setLoading(false);
        }, [dispatch, itemId, navigate, redirectOnNotFound, selectedOrderId]);

        return (
            <Box>
                <Button
                    sx={{ p: '2px' }}
                    startIcon={
                        <DeleteOutline sx={{ fontSize: '25px !important' }} />
                    }
                    onClick={openDialog}
                />
                <RemoveOrderModal
                    title={t('Delete Item from the Order')}
                    text={t('You are about to remove this item from an order.')}
                    deleteButtonText={`Delete item`}
                    isShowDeleteOrder={showDialog}
                    isLoadingDeleteOrder={loading}
                    onCloseDeleteOrder={closeDialog}
                    onSubmitDeleteOrder={submitDialog}
                />
            </Box>
        );
    },
);
