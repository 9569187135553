import { Box, Radio, Typography, alpha } from '@mui/material';
import { CheckOutlined } from '@mui/icons-material';
import { Proposal } from 'entities/Proposal/model/types/ProposalSchema';
import { getUserCanSeePrices } from 'entities/User/model/selectors/userPermissionsSeletors';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getPrice } from 'shared/lib/getPrice/getPrice';

interface OrderViewItemDetailedSupliersRadioProps {
    proposals: Proposal[];
    selectedProposalId: string;
    onChange: (p: Proposal) => void;
    disabled: boolean;
}

const subtextColor = '#828282';

export const OrderViewItemDetailedSupliersRadio = ({
    proposals,
    selectedProposalId,
    onChange,
    disabled,
}: OrderViewItemDetailedSupliersRadioProps) => {
    const { t } = useTranslation();

    const canSeePrices = useSelector(getUserCanSeePrices);

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap="8px"
            paddingBottom="8px"
        >
            {proposals?.length > 0 &&
                proposals.map((p) => (
                    <Box
                        sx={{ boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)' }}
                        key={p?.seller?.uid}
                        display="flex"
                        borderRadius="4px"
                    >
                        <Box
                            width="60px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            bgcolor={'rgba(63, 110, 183, 0.10)'}
                        >
                            <Radio
                                disabled={disabled || p?.isOutOfStock}
                                checked={p.uid === selectedProposalId}
                                onChange={() => onChange(p)}
                                value={p?.uid}
                            />
                        </Box>
                        <Box
                            width="100%"
                            p="12px 16px"
                            flex="1 1"
                            sx={
                                p?.isOutOfStock
                                    ? {
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                      }
                                    : {}
                            }
                            color={subtextColor}
                        >
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignContent="center"
                                gap="10px"
                                mb={p?.isOutOfStock ? '' : '6px'}
                            >
                                <Typography
                                    typography="openSans.subtitle1Medium"
                                    sx={(theme) => ({
                                        color: alpha(
                                            theme.palette.common?.black,
                                            0.85,
                                        ),
                                    })}
                                >
                                    {p.seller.name}
                                </Typography>
                                {p?.isPreferred && (
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        gap="4px"
                                        alignItems="start"
                                        sx={{
                                            padding: '3px 6px',
                                            backgroundColor: '#DBEFE1',
                                            borderRadius: '2px',
                                            color: '#000000',
                                        }}
                                    >
                                        <Typography typography="openSans.caption">
                                            {t('Preferred')}
                                        </Typography>
                                        <CheckOutlined
                                            sx={{
                                                width: '16px',
                                                height: '16px',
                                            }}
                                        />
                                    </Box>
                                )}
                            </Box>

                            {p?.isOutOfStock ? (
                                <Typography
                                    typography="openSans.subtitle2"
                                    sx={{ color: '#BF1B28' }}
                                >
                                    {t('Out of stock')}
                                </Typography>
                            ) : (
                                <>
                                    {canSeePrices && (
                                        <>
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                mb="2px"
                                            >
                                                <Typography typography="openSans.body2">
                                                    {t('Total')} (
                                                    {p.quantity || 0}
                                                    ):
                                                </Typography>

                                                <Typography
                                                    typography="openSans.h5"
                                                    sx={(theme) => ({
                                                        color: alpha(
                                                            theme.palette.common
                                                                ?.black,
                                                            0.85,
                                                        ),
                                                    })}
                                                >
                                                    {getPrice(p.prices.total)}
                                                </Typography>
                                            </Box>

                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                            >
                                                <Typography typography="openSans.body2">
                                                    {t('Taxes')}:
                                                </Typography>

                                                <Typography typography="openSans.body2">
                                                    {getPrice(p.prices.tax)}
                                                </Typography>
                                            </Box>
                                        </>
                                    )}
                                </>
                            )}
                        </Box>
                    </Box>
                ))}
        </Box>
    );
};
