import {
    PropertyCatalogCategoryList,
    PropertyCatalogItems,
    PropertyCatalogItemsMoveTo,
} from '..';
import { useSelector } from 'react-redux';
import {
    getCatalogItems,
    getCatalogItemsAreLoading,
} from 'entities/CatalogItem/model/selectors/catalogItemSelectors';
import { ReactNode, useMemo } from 'react';
import { Box } from '@mui/material';
import { useMatch } from 'react-router-dom';
import {
    getRouteGlobalCatalog,
    getRoutePropertiesCatalogInventoryCategories,
} from 'shared/const/router';
import { useInitialEffect } from 'shared/lib/hooks/useInitialEffect';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { addCatalogItemActions } from 'features/AddCatalogItem/model/slices/AddCatalogItemSlice';
import { useUnmountEffect } from 'shared/lib/hooks/useUnmountEffect';
import { getSelectCategoryId } from 'entities/Category/model/selectors/categorySelectors';

interface PropertyCatalogProps {
    isEditing?: boolean;
    moveTo: PropertyCatalogItemsMoveTo;
    actionButtons: ReactNode;
    onLeave?: () => void;
}

export const PropertyCatalog = ({
    isEditing,
    actionButtons,
    moveTo,
    onLeave,
}: PropertyCatalogProps) => {
    const itemsAreloading = useSelector(getCatalogItemsAreLoading);
    const selectedCategoryId = useSelector(getSelectCategoryId);
    const catalogItems = useSelector(getCatalogItems);
    const dispatch = useAppDispatch();

    const isGlobalCatalog = useMatch(
        getRouteGlobalCatalog(':propertyId') + '/*',
    );

    const isAddPropertiesCatalogInventoryItems = useMatch(
        getRoutePropertiesCatalogInventoryCategories(':propertyId') + '/*',
    );

    useInitialEffect(() => {
        if (isGlobalCatalog) {
            dispatch(addCatalogItemActions.setEditItemMode(true));
        }
    });

    useUnmountEffect(() => {
        if (onLeave) {
            onLeave();
        }
    });

    const showCatalogItems = !(
        !selectedCategoryId ||
        (catalogItems?.length === 0 && !itemsAreloading)
    );

    const screenHeight = useMemo(
        () =>
            isAddPropertiesCatalogInventoryItems || isGlobalCatalog
                ? '332px'
                : '385px',
        [isAddPropertiesCatalogInventoryItems, isGlobalCatalog],
    );

    const heightOfListForEditMode = useMemo(
        () =>
            isEditing ? `calc(100vh - ${screenHeight})` : `calc(100vh - 353px)`,
        [isEditing, screenHeight],
    );

    return (
        <Box
            width="100%"
            display="flex"
            style={{ flex: 1 }}
            maxHeight={heightOfListForEditMode}
        >
            <PropertyCatalogCategoryList
                isEditing={isEditing}
                moveTo={moveTo}
            />
            <Box
                flexBasis="50%"
                borderRadius="0 12px 12px 0"
                border="1px solid"
                borderColor="grey.500"
                display="flex"
                flexDirection="column"
                p="2px"
                style={{ flex: 1 }}
            >
                <PropertyCatalogItems
                    moveTo={moveTo}
                    showCatalogItems={showCatalogItems}
                    actionButtons={actionButtons}
                />
            </Box>
        </Box>
    );
};
