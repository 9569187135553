import { Box, Button, Typography } from '@mui/material';
import { UserRole, getUserRole } from 'entities/User';
import {
    getSelectedOrderId,
    getSelectedOrderStatus,
} from 'entities/Order/model/selectors/ordersSelectors';

import { CatalogItem } from 'entities/CatalogItem/model/types/CatalogItemSchema';
import { CatalogItemCheckbox } from 'features/CatalogItemCheckbox';
import { CatalogItemImage } from 'shared/ui/CatalogItemImage';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { OrderStatuses } from 'entities/Order/model/consts/orderStatuses';
import { UnitOfMeasureDisplay } from 'shared/ui/UnitOfMeasureDisplay/UnitOfMeasureDisplay';
import { catalogItemActions } from 'entities/CatalogItem/model/slices/CatalogItemSlice';
import { getCanEditCountOfQty } from 'entities/Order/model/selectors/ordersPermissions';
import { getPrice } from 'shared/lib/getPrice/getPrice';
import { getRouteOrdersViewItemDetailed } from 'shared/const/router';
import { getUserCanSeePrices } from 'entities/User/model/selectors/userPermissionsSeletors';
import { shouldShowUOM } from 'shared/lib/unitOfMeasureUtility/unitOfMeasureUtility';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { useGetItemDataUpdate } from 'features/CatalogItemCheckbox/lib/useGetItemDataUpdate';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface OrderGroupedItemsTableRowsProps {
    item: CatalogItem;
}

export const OrderGroupedItemsTableRow = ({
    item,
}: OrderGroupedItemsTableRowsProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const canSeePrices = useSelector(getUserCanSeePrices);
    const isEditCountOfQty = useSelector(getCanEditCountOfQty);
    const selectedOrderId = useSelector(getSelectedOrderId);
    const selectedOrderStatus = useSelector(getSelectedOrderStatus);
    const userRole = useSelector(getUserRole);

    const shouldSeeWarnings =
        userRole !== UserRole.MAINTENANCE_TECHNICIAN &&
        [
            OrderStatuses.DRAFT,
            OrderStatuses.IN_REVIEW,
            OrderStatuses.PENDING_APPROVAL,
        ].includes(selectedOrderStatus);

    const isHidePrice = userRole === UserRole.MAINTENANCE_TECHNICIAN;
    const canSeeSeller = !(
        userRole === UserRole.MAINTENANCE_TECHNICIAN &&
        selectedOrderStatus === OrderStatuses.DRAFT
    );

    const canViewProposals = ![UserRole.MAINTENANCE_TECHNICIAN].includes(
        userRole,
    );

    const { isActive, idToEdit, itemIsInOrder, qty } = useGetItemDataUpdate({
        item,
    });

    const proposalsTotal = item?.proposals?.length;
    const sellerName = item?.selectedProposal?.name;
    const sellerActive = item?.selectedProposal?.isOperationsActivated;

    const pricePerItem = item?.selectedProposal?.price || item?.price;

    const total = qty * Number(pricePerItem);

    const openItem = () => {
        navigate(getRouteOrdersViewItemDetailed(selectedOrderId, item.id));
        dispatch(catalogItemActions.setSelectedItem(item));
    };

    return (
        <Box
            data-testclass="order-item"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                border: '1px solid #E9E8E8',
                borderRadius: '4px',
                mb: '15px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    gap: '24px',
                    padding: '12px',
                }}
            >
                <Box sx={{ display: 'flex', flex: '52%' }}>
                    <Box
                        width="70px"
                        height="70px"
                        flex="0 0 70px"
                        p="4px"
                        mr="16px"
                    >
                        <CatalogItemImage src={item.thumbnailUrl} />
                    </Box>
                    <Box>
                        <Typography
                            typography={'openSans.subtitle1Medium'}
                            data-testclass="order-item-title"
                        >
                            {item.title}
                        </Typography>
                        <Typography
                            typography={'openSans.body2'}
                            data-testclass="order-item-sku"
                        >
                            {'SKU'}:{' '}
                            <Typography
                                typography="openSans.body2Medium"
                                component="strong"
                            >
                                {item.sku}
                            </Typography>
                        </Typography>
                        {shouldShowUOM && (
                            <Typography
                                typography={'openSans.body2'}
                                data-testclass="order-item-sku"
                            >
                                <UnitOfMeasureDisplay
                                    unitCode={item.uom}
                                    quantity={item.qtyPerUom}
                                ></UnitOfMeasureDisplay>
                            </Typography>
                        )}
                    </Box>
                </Box>
                {!isEditCountOfQty ? (
                    <>
                        <Box
                            sx={{
                                flex: '23%',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography
                                typography="openSans.body2"
                                data-testclass="order-item-qty"
                            >
                                {qty}
                            </Typography>
                        </Box>
                        {canSeePrices && !isHidePrice && (
                            <Box
                                sx={{
                                    flex: '15%',
                                    display: 'flex',
                                    justifyContent: 'right',
                                }}
                            >
                                <Typography
                                    typography="openSans.body"
                                    fontWeight={600}
                                    data-testclass="order-item-uom-price"
                                >
                                    {getPrice(total)}
                                </Typography>
                            </Box>
                        )}
                    </>
                ) : (
                    <>
                        <Box
                            sx={{
                                flex: '23%',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            {!item?.replacedWith && (
                                <>
                                    <CatalogItemCheckbox
                                        isOrderDetails
                                        hideSwitcher
                                        minimized
                                        showQtyCheckbox
                                        isActive={isActive}
                                        quickEdit
                                        isNoGap
                                        qty={qty}
                                        itemId={item.id}
                                        itemUid={item.uuid}
                                        itemIdToEdit={idToEdit}
                                        isInOrder={itemIsInOrder}
                                    />
                                    {qty > item.selectedProposal?.quantity &&
                                        shouldSeeWarnings && (
                                            <InfoOutlinedIcon
                                                sx={{
                                                    color: 'red',
                                                    marginLeft: '8px',
                                                }}
                                            />
                                        )}
                                </>
                            )}
                        </Box>
                        {canSeePrices && !isHidePrice && (
                            <Box
                                sx={{
                                    flex: '15%',
                                    display: 'flex',
                                    justifyContent: 'right',
                                }}
                            >
                                {!item?.replacedWith && (
                                    <Typography
                                        typography="openSans.body"
                                        fontWeight={600}
                                        data-testclass="order-item-total-price"
                                    >
                                        {getPrice(total)}
                                    </Typography>
                                )}
                            </Box>
                        )}
                    </>
                )}
            </Box>
            {item.replacedWith && (
                <Box
                    data-testclass="order-item-substitution"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        ml: '100px',
                        borderTop: '1px solid #E9E8E8',
                        mt: '12px',
                        padding: '16px 12px 16px 0',
                    }}
                >
                    <Typography
                        mb="6px"
                        color="#23BC86"
                        typography={'openSans.subtitle2'}
                    >
                        {`Substitution`}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '24px',
                            flex: 1,
                        }}
                    >
                        <Box
                            sx={{
                                flex: '50%',
                                display: 'flex',
                            }}
                        >
                            <Typography
                                typography={'openSans.subtitle1Medium'}
                                data-testclass="order-item-substitution-title"
                            >
                                {item.replacedWith}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                flex: '25%',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography
                                typography="openSans.body2"
                                data-testclass="order-item-substitution-qty"
                            >
                                {qty}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                flex: '15%',
                                display: 'flex',
                                justifyContent: 'right',
                            }}
                        >
                            <Typography
                                typography="openSans.body"
                                data-testclass="order-item-substitution-total-price"
                            >
                                {getPrice(total)}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            )}

            <Box sx={{ height: '1px', background: '#E9E8E8', flexGrow: 1 }} />
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="12px"
            >
                <Box display="flex" alignItems="center">
                    {canSeeSeller && (
                        <>
                            <Typography
                                color={'#1D617A'}
                                typography="openSans.subtitle1Medium"
                                data-testclass="order-item-selected-supplier"
                            >
                                {canSeeSeller ? sellerName : ''}
                            </Typography>
                            {!sellerActive &&
                                sellerName &&
                                shouldSeeWarnings && (
                                    <InfoOutlinedIcon
                                        sx={{
                                            color: 'red',
                                            marginLeft: '8px',
                                        }}
                                    />
                                )}
                        </>
                    )}
                </Box>
                {proposalsTotal !== 0 && canViewProposals && (
                    <Button sx={{ p: '4px' }} onClick={openItem}>
                        {t('View other suppliers')} ({proposalsTotal || 0})
                    </Button>
                )}
            </Box>
        </Box>
    );
};
