import { Box, Button, Typography, alpha } from '@mui/material';
import {
    EditMinAllowedInStockInput,
    getEditMinAllowedInStockIsLoading,
    getEditMinAllowedInStockValue,
    getEditMinAllowedInStockValueIsChanged,
} from 'features/EditMinAllowedInStock';
import {
    getRouteGlobalCatalog,
    getRoutePropertiesCatalogInventory,
    getRoutePropertiesCatalogSelected,
    getRoutePropertiesGlobalCatalogSelected,
} from 'shared/const/router';
import {
    getTermFromCode,
    shouldShowUOM,
} from 'shared/lib/unitOfMeasureUtility/unitOfMeasureUtility';
import {
    getUserCanEditPropertyCatalog,
    getUserCanSeePrices,
} from 'entities/User/model/selectors/userPermissionsSeletors';
import { memo, useEffect, useState } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router-dom';

import { ArrowBack } from '@mui/icons-material';
import { CatalogItemImage } from 'shared/ui/CatalogItemImage';
import { Loader } from 'shared/ui/Loader/Loader';
import { MoveToInventoryCatalogItemButton } from 'features/moveToInventoryCatalogItem';
import { MoveToInventoryDialog } from 'features/moveToInventoryCatalogItem/ui/MoveToInventoryDialog/MoveToInventoryDialog';
import { PropertyCardRow } from 'entities/Property';
import { PropertyCatalogItemsMoveTo } from 'widgets/PropertyCatalog/models/consts/propertyCatalogItemsListTypes';
import { Proposal } from 'entities/Proposal/model/types/ProposalSchema';
import { RemoveCatalogItem } from 'features/removeCatalogItem';
import { SuppliersList } from '../SuppliersList';
import { catalogItemActions } from 'entities/CatalogItem/model/slices/CatalogItemSlice';
import { editMinAllowedService } from 'features/EditMinAllowedInStock/model/services/editMinAllowedService';
import { getCatalogItemsShowDetailedView } from 'entities/CatalogItem/model/selectors/catalogItemSelectors';
import { getPrice } from 'shared/lib/getPrice/getPrice';
import { getPropertyCatalogId } from 'features/fetchPropertyById/model/selectors/fetchPropertyByIdSelectors';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export interface CatalogItemProps {
    id: string;
    sku: string;
    uom: string;
    price: number;
    uomPrice?: number;
    qtyPerUom: number;
    isOrderPage: boolean;
    title: string;
    imageUrl?: string;
    minAllowedInStock: number;
    moveTo?: PropertyCatalogItemsMoveTo;
    isLocal: boolean;
    onCancel: () => void;
    proposals: Proposal[];
    activatedAccounts: { [key: string]: boolean[] };
}

export const PropertyCatalogItemDetailed = memo(
    ({
        sku,
        uom,
        price,
        uomPrice,
        qtyPerUom,
        isOrderPage,
        title,
        imageUrl,
        id,
        minAllowedInStock,
        moveTo,
        isLocal,
        onCancel,
        proposals,
        activatedAccounts,
    }: CatalogItemProps) => {
        const { t } = useTranslation();
        const dispatch = useAppDispatch();
        const navigate = useNavigate();
        const params = useParams();

        const isOpened = useSelector(getCatalogItemsShowDetailedView);
        const catalogId = useSelector(getPropertyCatalogId);
        const minAllowedValue = useSelector(getEditMinAllowedInStockValue);
        const loading = useSelector(getEditMinAllowedInStockIsLoading);
        const canEditCatalog = useSelector(getUserCanEditPropertyCatalog);
        const canSeePrices = useSelector(getUserCanSeePrices);

        const isMoveToCatalog = moveTo === PropertyCatalogItemsMoveTo.CATALOG;
        const showActionsPannel = isMoveToCatalog && isLocal;

        const isGlobalCatalog = useMatch(
            getRouteGlobalCatalog(':propertyId') + '/*',
        );

        const isInventoryTable = useMatch(
            getRoutePropertiesCatalogInventory(':propertyId') + '/*',
        );

        const isPropertyCatalogRoute = useMatch(
            getRoutePropertiesCatalogSelected(':propertyId', ':categoryId') +
                '/*',
        );

        const valueChanged = useSelector(
            getEditMinAllowedInStockValueIsChanged,
        );

        const [initialMinAllowedInStock, setInitialMinAllowedInStock] =
            useState(minAllowedInStock);

        const goBack = () => {
            if (isOrderPage) {
                navigate(-1);
                onCancel();
                return;
            }

            if (isGlobalCatalog) {
                navigate(
                    getRoutePropertiesGlobalCatalogSelected(
                        params?.propertyId,
                        params?.categoryId,
                    ),
                );
            } else if (isInventoryTable) {
                navigate(-1);
            } else if (isPropertyCatalogRoute) {
                navigate(
                    getRoutePropertiesCatalogSelected(
                        params?.propertyId,
                        params?.categoryId,
                    ),
                );
            }
            onCancel();
        };

        const onSubmit = async () => {
            const data = {
                items: [{ id, minAllowedInStock: minAllowedValue }],
            };

            const response = await dispatch(
                editMinAllowedService({ catalogId, data }),
            );

            if (response.meta.requestStatus == 'fulfilled') {
                setInitialMinAllowedInStock(minAllowedValue);

                if (isMoveToCatalog) {
                    dispatch(
                        catalogItemActions.updateItem(
                            response.payload.patched[0],
                        ),
                    );
                }

                toast(t('Min on Hand value updated'));
            }
        };

        useEffect(
            () => () => {
                if (isOpened) {
                    dispatch(catalogItemActions.closeDetailedItem());
                }
            },
            [dispatch, isOpened],
        );

        return (
            <Box
                gap="16px"
                width="100%"
                height="100%"
                display="flex"
                flexDirection="column"
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    mb="16px"
                    flex="1 1"
                    pr="16px"
                >
                    <Button
                        onClick={goBack}
                        sx={(theme) => ({
                            color: alpha(theme.palette.common.black, 0.5),
                            p: '0',
                            minWidth: '24px',
                        })}
                    >
                        <ArrowBack
                            sx={{
                                width: '24px',
                                height: '24px',
                            }}
                        />
                    </Button>
                    {showActionsPannel && canEditCatalog && (
                        <Box display="flex" gap="24px">
                            <RemoveCatalogItem isSingleItem />
                            <MoveToInventoryCatalogItemButton isSingleItem />
                        </Box>
                    )}
                </Box>
                <Box width="100%" sx={{ overflowY: 'scroll' }} pr="16px">
                    <Box
                        borderRadius="4px"
                        style={{
                            aspectRatio: '380/220',
                            height: 'auto',
                            maxHeight: '320px',
                            width: '100%',
                        }}
                    >
                        <CatalogItemImage src={imageUrl} />
                    </Box>
                    <Box flex="1">
                        <PropertyCardRow
                            bordered
                            name={t('Name')}
                            value={title}
                        />
                        <PropertyCardRow bordered name={t('SKU')} value={sku} />
                        {shouldShowUOM(uom) ? (
                            <>
                                <PropertyCardRow
                                    bordered
                                    name={t('Qty per UOM')}
                                    value={qtyPerUom}
                                />
                                <PropertyCardRow
                                    bordered
                                    name={t('UOM')}
                                    value={getTermFromCode(uom)}
                                />
                                {canSeePrices && (
                                    <PropertyCardRow
                                        bordered
                                        name={t('Price per UOM')}
                                        value={getPrice(uomPrice || price)}
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                {canSeePrices && (
                                    <PropertyCardRow
                                        bordered
                                        name={t('Price')}
                                        value={getPrice(uomPrice || price)}
                                    />
                                )}
                            </>
                        )}
                        {isGlobalCatalog && (
                            <>
                                <Typography
                                    sx={{ marginY: '16px', color: '#000000D9' }}
                                    typography="openSans.subtitle1Medium"
                                >
                                    {t('Offers from Suppliers ')} (
                                    {proposals?.length || 0})
                                </Typography>
                                <SuppliersList
                                    proposals={proposals}
                                    activatedAccounts={activatedAccounts}
                                />
                            </>
                        )}
                        {isLocal && (
                            <>
                                {isOrderPage ? (
                                    <PropertyCardRow
                                        bordered
                                        name={t('Min on Hand')}
                                        value={initialMinAllowedInStock || '0'}
                                    />
                                ) : (
                                    <Box m="24px 0 48px">
                                        <EditMinAllowedInStockInput
                                            readOnly={!canEditCatalog}
                                            initialValue={
                                                initialMinAllowedInStock
                                            }
                                        />
                                    </Box>
                                )}
                            </>
                        )}
                    </Box>
                </Box>
                {isLocal && !isOrderPage && (
                    <Box pr="16px" display="flex" justifyContent="flex-end">
                        <Button onClick={goBack}>{t('Cancel')}</Button>
                        {canEditCatalog && (
                            <Button
                                disabled={!valueChanged || loading}
                                startIcon={
                                    loading && <Loader color="inherit" />
                                }
                                variant="contained"
                                onClick={onSubmit}
                            >
                                {t('Save')}
                            </Button>
                        )}
                    </Box>
                )}

                <MoveToInventoryDialog />
            </Box>
        );
    },
);
