import { Box, Button, Skeleton, Typography, alpha } from '@mui/material';
import { ArrowBack, DeleteOutline } from '@mui/icons-material';

interface PageTitleProps {
    defaultTitle?: string;
    editTitle: string;
    backTitle: string;
    isEditing?: boolean;
    showEditButton?: boolean;
    isLoading?: boolean;
    isDraftTab?: boolean;
    isShowDialog?: boolean;
    openDialog?: () => void;
    onBack: () => void;
}

export const PageTitle = ({
    isEditing,
    showEditButton = true,
    editTitle,
    backTitle,
    onBack,
    isLoading,
    isDraftTab,
    isShowDialog,
    openDialog,
}: PageTitleProps) => {
    return (
        <>
            {isEditing ? (
                <Button
                    onClick={onBack}
                    startIcon={
                        <ArrowBack sx={{ width: '24px', height: '24px' }} />
                    }
                    sx={(theme) => ({
                        color: alpha(theme.palette.common.black, 0.5),
                        p: 0,
                        mb: '26px',
                        textTransform: 'inherit',
                        ':hover': {
                            bgcolor: 'transparent',
                        },
                        '.MuiButton-startIcon': {
                            ml: 0,
                        },
                    })}
                    style={{
                        alignSelf: 'start',
                    }}
                >
                    {showEditButton && (
                        <Typography typography="openSans.body2">
                            {backTitle}
                        </Typography>
                    )}
                </Button>
            ) : null}
            {!isLoading ? (
                <Box
                    display="flex"
                    alignItems="center"
                    mb={isEditing ? '24px' : '0'}
                >
                    {isEditing && (
                        <Typography typography={'poppins.h5'}>
                            {editTitle}
                        </Typography>
                    )}
                    {isDraftTab && (
                        <Button
                            sx={{
                                '& span': {
                                    margin: 0,
                                },
                                padding: '5px',
                                minWidth: 'auto',
                            }}
                            startIcon={
                                <DeleteOutline
                                    color={
                                        isShowDialog ? 'primary' : 'disabled'
                                    }
                                    sx={{
                                        fontSize: '25px !important',
                                    }}
                                />
                            }
                            onClick={openDialog}
                        />
                    )}
                </Box>
            ) : (
                <>
                    {isEditing && (
                        <Skeleton
                            variant="rounded"
                            height="32px"
                            sx={{ width: '280px', mb: '24px' }}
                        />
                    )}
                </>
            )}
        </>
    );
};
