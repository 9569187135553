import { Box, Link, List, ListItem, Skeleton, Typography } from '@mui/material';
import { AppLink } from 'shared/ui/AppLink/AppLink';
import { matchPath, useLocation } from 'react-router-dom';
import { contentHeight } from 'shared/const/styles';
import { VersionInfo } from 'widgets/VersionInfo';
import {
    getRouteOrders,
    getRouteProperties,
    getRouteMainReports,
    getRouteSettings,
    getRouteMainVendors,
} from 'shared/const/router';
import {
    Badge,
    Apartment,
    ReceiptRounded,
    AssessmentRounded,
    Settings,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { UserRole, getUserRole } from 'entities/User';
import { useSelector } from 'react-redux';
import { getAuthIsLoading } from 'entities/User/model/selectors/userSelectors';
import { MouseEventHandler, useMemo } from 'react';

import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { getSelectedPropertyId } from 'entities/Property/model/selectors/PropertySelectors';

export const Navbar = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const userRole = useSelector(getUserRole);
    const selectedPropertyId = useSelector(getSelectedPropertyId);
    const userIsloading = useSelector(getAuthIsLoading);

    const navBarItemsList = useMemo(
        () => [
            {
                label: t('Properties'),
                route: getRouteProperties(),
                icon: Apartment,
                roles: [
                    UserRole.PMC_ADMIN,
                    UserRole.PROPERTY_MANAGER,
                    UserRole.MAINTENANCE_TECHNICIAN,
                    UserRole.MAINTENANCE_SUPERVISOR,
                ],
            },
            {
                label: t('Orders'),
                route: getRouteOrders(),
                icon: ReceiptRounded,
                roles: [
                    UserRole.PMC_ADMIN,
                    UserRole.PROPERTY_MANAGER,
                    UserRole.MAINTENANCE_TECHNICIAN,
                    UserRole.MAINTENANCE_SUPERVISOR,
                ],
            },
            {
                label: t('Vendors'),
                route: getRouteMainVendors(selectedPropertyId),
                icon: Badge,
                roles: [
                    UserRole.PMC_ADMIN,
                    UserRole.PROPERTY_MANAGER,
                    UserRole.MAINTENANCE_TECHNICIAN,
                    UserRole.MAINTENANCE_SUPERVISOR,
                ],
            },
            {
                label: t('Reports'),
                route: getRouteMainReports(),
                icon: AssessmentRounded,
                roles: [
                    UserRole.PMC_ADMIN,
                    UserRole.PROPERTY_MANAGER,
                    UserRole.MAINTENANCE_SUPERVISOR,
                ],
            },
            {
                label: t('Settings'),
                route: getRouteSettings(),
                icon: Settings,
                roles: [UserRole.PMC_ADMIN, UserRole.PROPERTY_MANAGER],
            },
        ],
        [t, selectedPropertyId],
    );

    return (
        <>
            <Box
                bgcolor="background.paper"
                height={contentHeight}
                minWidth="200px"
                maxWidth="260px"
                position="fixed"
                zIndex="drawer"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
            >
                <List sx={{ pt: '24px' }}>
                    {!userIsloading ? (
                        navBarItemsList.map((item) => {
                            const isActive = matchPath(
                                item.route + '/*',
                                location.pathname,
                            );

                            const isHidden =
                                item?.roles &&
                                item?.roles?.includes(userRole) === false;
                            const Icon = item.icon;

                            const disabledClick: MouseEventHandler<
                                HTMLAnchorElement
                            > = (e) => {
                                e.preventDefault();
                            };

                            if (isHidden) {
                                return null;
                            }

                            return (
                                <ListItem
                                    data-testid={`Menu-${item.label}`}
                                    sx={{
                                        p: 0,
                                        pl: '24px',
                                        ':hover': !isActive && {
                                            bgcolor: 'action.hover',
                                            cursor: 'pointer',
                                        },
                                        bgcolor: isActive && 'primary.main',
                                        color: isActive && 'common.white',
                                    }}
                                    key={item.route}
                                >
                                    <Box
                                        component={AppLink}
                                        onClick={
                                            isActive ? disabledClick : () => {}
                                        }
                                        to={item.route}
                                        display="flex"
                                        gap="12px"
                                        alignItems="center"
                                        p="12px 0"
                                        width="100%"
                                    >
                                        <Icon
                                            sx={{
                                                color: isActive
                                                    ? 'common.white'
                                                    : 'action.active',
                                            }}
                                        />
                                        <Typography
                                            color={
                                                isActive
                                                    ? 'common.white'
                                                    : 'action.active'
                                            }
                                        >
                                            {item.label}
                                        </Typography>
                                    </Box>
                                </ListItem>
                            );
                        })
                    ) : (
                        <Box display="flex" flexDirection="column" gap="2px">
                            <Skeleton variant="rectangular" height="48px" />
                            <Skeleton variant="rectangular" height="48px" />
                            <Skeleton variant="rectangular" height="48px" />
                        </Box>
                    )}
                </List>
                <Box p="0 0 12px 24px">
                    <Box
                        display="flex"
                        component={Link}
                        href={'mailto:help@re-ops.co'}
                        sx={{ textDecoration: 'none' }}
                        gap="8px"
                    >
                        <HelpOutlineOutlinedIcon />
                        <Typography
                            typography="openSans.button"
                            lineHeight="24px"
                        >
                            {t('Help')}
                        </Typography>
                    </Box>
                    <Typography
                        mt={'12px'}
                        mr={'24px'}
                        align={'center'}
                        style={{ opacity: '0.5' }}
                    >
                        <VersionInfo />
                    </Typography>
                </Box>
            </Box>
            <Box height={contentHeight} minWidth="200px" maxWidth="260px" />
        </>
    );
};
