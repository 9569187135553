/* eslint-disable max-len */
import { Box, Typography } from '@mui/material';
import {
    getSelectedCatalogItems,
    getSelectedCatalogItemsTotal,
} from 'entities/CatalogItem/model/selectors/catalogItemSelectors';

import {
    getCanEditOwnSelectedOrder,
    getCanEditSelectedOrder,
    getCanRejectSeletedOrder,
} from 'entities/Order/model/selectors/ordersPermissions';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {
    getSelectedOrderId,
    getSelectedOrderIsLoading,
    getSelectedOrderStatus,
} from 'entities/Order/model/selectors/ordersSelectors';
import { getUserCanAddOrderItem } from 'entities/User/model/selectors/userPermissionsSeletors';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { OrderGroupedItems } from 'widgets/OrderGroupedItems';
import { CatalogItem } from 'entities/CatalogItem/model/types/CatalogItemSchema';
import { RejectOrderButton } from 'features/changeOrderStatus';
import { OrderViewPageApproveButton } from './OrderViewPageApproveButton';
import { AddItemsToOrderButton } from 'features/AddItemsToOrder';
import { getProposalFilterIsActive } from 'features/OrderProposalFilter/model/selectors/orderProposalFilteSelectors';
import { EmptyPlaceholder } from 'shared/ui/EmptyPlaceholder/EmptyPlaceholder';
import openBoxSearch from 'shared/assets/svg/open-box-search.svg';
import { Vendor } from 'entities/Vendors';
import { getUserRole, UserRole } from 'entities/User';
import { OrderStatuses } from 'entities/Order/model/consts/orderStatuses';

interface OrderViewPageItemsProps {
    vendors?: Vendor[];
    groupedItems: Record<string, CatalogItem[]>;
    onBack: () => void;
}

export const OrderViewPageItems = ({
    vendors = [],
    groupedItems,
    onBack,
}: OrderViewPageItemsProps) => {
    const { t } = useTranslation();
    const totalItemsCount = useSelector(getSelectedCatalogItemsTotal);
    const orderId = useSelector(getSelectedOrderId);
    const orderStatus = useSelector(getSelectedOrderStatus);
    const orderIsLoading = useSelector(getSelectedOrderIsLoading);
    const canEditOwnSelectedOrder = useSelector(getCanEditOwnSelectedOrder);
    const canEditSelectedOrder = useSelector(getCanEditSelectedOrder);
    const canAddOrderItem = useSelector(getUserCanAddOrderItem);
    const canReject = useSelector(getCanRejectSeletedOrder);
    const filterIsActive = useSelector(getProposalFilterIsActive);
    const currentUserRole = useSelector(getUserRole);
    const selectedItems = useSelector(getSelectedCatalogItems);

    const shouldSeeWarnings =
        currentUserRole !== UserRole.MAINTENANCE_TECHNICIAN &&
        [
            OrderStatuses.DRAFT,
            OrderStatuses.IN_REVIEW,
            OrderStatuses.PENDING_APPROVAL,
        ].includes(orderStatus);

    const canAddItems =
        (canEditSelectedOrder && canAddOrderItem) || canEditOwnSelectedOrder;

    const hasItems = totalItemsCount > 0;

    const areAllSuppliersOperationsActivated = (
        order: CatalogItem[],
        suppliers: Vendor[],
    ): boolean => {
        return order.every((orderItem) => {
            const supplier = suppliers.find(
                (s) => s.externalUuid === orderItem.selectedProposal.sellerUid,
            );

            return supplier?.isOperationsActivated === true;
        });
    };

    const isQuantityAvailable = (order: CatalogItem[]): boolean => {
        return order.every((orderItem) => {
            return (
                selectedItems[orderItem.id].qty <=
                orderItem.selectedProposal.quantity
            );
        });
    };

    const populateOperationsAccountStatus = (
        source: Record<string, CatalogItem[]>,
        suppliers: Vendor[],
    ): Record<string, CatalogItem[]> => {
        const output: Record<string, CatalogItem[]> = {};

        Object.keys(source).forEach((groupKey) => {
            output[groupKey] = source[groupKey].map((orderItem) => {
                const supplier = suppliers.find(
                    (s) =>
                        s.externalUuid === orderItem.selectedProposal.sellerUid,
                );
                const proposal = orderItem.proposals.find(
                    (p) => p.uid === orderItem.selectedProposal.uid,
                );

                const newOrderItem = {
                    ...orderItem,
                    selectedProposal: {
                        ...orderItem.selectedProposal,
                        quantity: proposal?.quantity,
                    },
                };

                if (supplier) {
                    newOrderItem.selectedProposal.isOperationsActivated =
                        supplier.isOperationsActivated;
                }

                return newOrderItem;
            });
        });

        return output;
    };

    const extendedGroupedItems = populateOperationsAccountStatus(
        groupedItems,
        vendors,
    );

    const canBeApproved = {
        activation: areAllSuppliersOperationsActivated(
            Object.values(groupedItems).flat(),
            vendors,
        ),
        quantity: isQuantityAvailable(
            Object.values(extendedGroupedItems).flat(),
        ),
    };

    const showNoFilteredItems =
        filterIsActive &&
        Object.keys(groupedItems).length === 0 &&
        !orderIsLoading;

    return (
        <Box height={'calc(100vh - 112px)'} position="relative">
            <Box p="16px 16px 96px" height="100%">
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent={!hasItems ? 'center' : undefined}
                    gap="8px"
                    height="100%"
                    sx={{ overflowY: 'scroll', margin: '-10px' }}
                >
                    <Box mb="16px">
                        {!orderIsLoading && canAddItems && (
                            <AddItemsToOrderButton />
                        )}
                    </Box>
                    {showNoFilteredItems && (
                        <Typography
                            pb="24px"
                            sx={{ opacity: 0.5 }}
                            typography="openSans.body2Medium"
                        >
                            {t('There is no Items in Selected Filter')}.
                        </Typography>
                    )}
                    {!(hasItems && orderIsLoading) &&
                        Object.entries(extendedGroupedItems).map(
                            ([categoryId, items]) => (
                                <OrderGroupedItems
                                    key={categoryId}
                                    items={items}
                                    categoryId={categoryId}
                                />
                            ),
                        )}
                    <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {!hasItems && !orderIsLoading && (
                            <Box width="366px">
                                <EmptyPlaceholder image={openBoxSearch}>
                                    <Typography
                                        mt="16px"
                                        sx={{ opacity: 0.6 }}
                                        typography="openSans.body2Medium"
                                        align="center"
                                    >
                                        {t(
                                            "The order doesn't have any items yet, so you can't confirm or reject an empty order",
                                        )}
                                        .
                                    </Typography>
                                </EmptyPlaceholder>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
            <Box
                display="flex"
                justifyContent="space-between"
                p="12px 16px 16px"
                bgcolor="background.paper"
                position="absolute"
                width="100%"
                bottom="0px"
                borderRadius="16px"
            >
                <Box display="flex" sx={{ alignItems: 'center' }}>
                    {Object.values(canBeApproved).some(
                        (value: boolean) => value === false,
                    ) &&
                        shouldSeeWarnings && (
                            <>
                                <InfoOutlinedIcon
                                    sx={{
                                        color: 'red',
                                        marginRight: '8px',
                                    }}
                                />
                                <Typography
                                    sx={{ opacity: 0.85, fontSize: '13px' }}
                                    typography="openSans.body"
                                >
                                    {canBeApproved.activation === false
                                        ? t(
                                              'Supplier accounts must be activated to submit order.',
                                          )
                                        : t(
                                              'Quantity exceeds available quantity in stock.',
                                          )}
                                </Typography>
                            </>
                        )}
                </Box>
                <Box display="flex" flexDirection="column">
                    <Box display="flex" alignSelf={'flex-end'} gap="16px">
                        {canReject && orderStatus !== 'DRAFT' && (
                            <RejectOrderButton
                                orderId={orderId}
                                isNoItemsInOrder={!hasItems}
                            />
                        )}
                        <OrderViewPageApproveButton
                            canBeApproved={Object.values(canBeApproved).every(
                                (value: boolean) => value === true,
                            )}
                            isNoItemsInOrder={!hasItems}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
