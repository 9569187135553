import { toast } from 'react-toastify';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { reorderInventoryItemService } from '../services/reorderInventoryItemService';
import { ReorderInventoryItemSchema } from '../types/ReorderInventoryItemSchema';

const initialState: ReorderInventoryItemSchema = {
    isEditFlow: false,
    isEditFlowFromCatigories: false,
    isSwap: false,
};

export const ReorderInventoryItemSlice = createSlice({
    name: 'ReorderInventoryItem',
    initialState,
    reducers: {
        setEditFlow: (state, action: PayloadAction<boolean>) => {
            state.isEditFlow = action.payload;
        },
        setInventoryOrderSwap: (state, action: PayloadAction<boolean>) => {
            state.isSwap = action.payload;
        },
        setEditFlowFromCatigories: (state, action: PayloadAction<boolean>) => {
            state.isEditFlowFromCatigories = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(reorderInventoryItemService.pending, (state) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(reorderInventoryItemService.fulfilled, (state) => {
                state.isLoading = false;
                toast('Items reordered');
            })
            .addCase(reorderInventoryItemService.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const { actions: reorderInventoryItemActions } =
    ReorderInventoryItemSlice;
export const { reducer: reorderInventoryItemReducer } =
    ReorderInventoryItemSlice;
